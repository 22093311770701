import cornerstone from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";
const convertToVector3 = cornerstoneTools.import('util/convertToVector3');

/**
 * Projects an image point to a patient point
 * @export @public @method
 * @name imagePointToPatientPoint
 *
 * @param  {Object} imagePoint   The image point.
 * @param  {Object} imagePlane   The image plane used for projection.
 * @returns {Object}              The projected coordinates.
 */
export function imagePointToPatientPoint(imagePoint, imagePlane) {
    const rowCosines = convertToVector3(imagePlane.rowCosines);
    const columnCosines = convertToVector3(imagePlane.columnCosines);
    const imagePositionPatient = convertToVector3(
        imagePlane.imagePositionPatient
    );

    const x = rowCosines.clone().multiplyScalar(imagePoint.x);

    x.multiplyScalar(imagePlane.columnPixelSpacing);
    const y = columnCosines.clone().multiplyScalar(imagePoint.y);

    y.multiplyScalar(imagePlane.rowPixelSpacing);
    const patientPoint = x.add(y);

    patientPoint.add(imagePositionPatient);

    return patientPoint;
}

export const getDistance = (imageId, patientPoint) => {
    const imagePlane = cornerstone.metaData.get(
        'imagePlaneModule',
        imageId
    );

    // Skip if the image plane is not ready
    if (
        !imagePlane ||
        !imagePlane.imagePositionPatient ||
        !imagePlane.rowCosines ||
        !imagePlane.columnCosines
    ) {
        return;
    }

    const imagePosition = convertToVector3(imagePlane.imagePositionPatient);
    const row = convertToVector3(imagePlane.rowCosines);
    const column = convertToVector3(imagePlane.columnCosines);
    const normal = column.clone().cross(row.clone());
    return Math.abs(
        normal.clone().dot(imagePosition) - normal.clone().dot(patientPoint)
    );
}


export const getNearestStackIndex = (imageIds, startIndex, endIndex, patientPoint) => {
    if(endIndex - startIndex < 5) {
        let minDistance = Number.MAX_VALUE;
        let nearestIndex = -1;
        for(let i = startIndex; i <= endIndex; i++) {
            const distance = getDistance(imageIds[i], patientPoint);
            if (distance < minDistance) {
                minDistance = distance;
                nearestIndex = i;
            }
        }
        return nearestIndex;
    }
    const startDistance = getDistance(imageIds[startIndex], patientPoint);
    const endDistance = getDistance(imageIds[endIndex], patientPoint);
    const midIndex = startIndex + Math.floor((endIndex - startIndex) / 2);
    if(startDistance < endDistance) {
        return getNearestStackIndex(imageIds, startIndex, midIndex, patientPoint);
    } else {
        return getNearestStackIndex(imageIds, midIndex, endIndex, patientPoint);
    }
};
