import React from 'react';
import cornerstone from "cornerstone-core";
import {connect} from "react-redux";
import GridToolButton from "./GridToolButton";
import {changeCineTool, changeCurrentTool, changeHangingLayout, setMPRModeImageId} from 'Store/Actions';
import {NotificationManager} from "react-notifications";
import ToolbarButton from "Components/Toolbar/ToolbarButton";
import ToolbarSplitButton from "Components/Toolbar/ToolbarSplitButton";
import WindowLevelButton from "./WindowLevelButton";

const siteToolList = [
    {
        id: 'Pan',
        type: 'toggle',
        label: 'Pan',
        icon: 'tool-move',
        mode: 'normal',
        action: 'onChangeTool',
        showInComplete: true,
        position: 'bar',
    },
    {
        id: 'Zoom',
        type: 'toggle',
        label: 'Zoom',
        icon: 'tool-zoom',
        mode: 'normal',
        action: 'onChangeTool',
        showInComplete: true,
        position: 'bar',
    },
    {
        id: 'Wwwc',
        type: 'toggle',
        label: 'Window Level',
        icon: 'tool-window-level',
        mode: 'normal',
        action: 'onChangeTool',
        showInComplete: true,
        position: 'bar',
    },
    {
        id: 'Marker',
        type: 'toggle',
        label: 'Marker',
        icon: 'tool-probe',
        mode: 'normal',
        action: 'onChangeTool',
        showInComplete: false,
        position: 'bar',
    },
    {
        id: 'MarkerFreehand',
        type: 'toggle',
        label: 'Marker Freehand',
        icon: 'tool-freehand',
        mode: 'normal',
        action: 'onChangeTool',
        showInComplete: false,
        position: 'bar',
    },
    {
        id: 'MPR',
        type: 'action',
        label: 'MPR',
        icon: 'icon-mpr',
        mode: 'normal',
        action: 'onMPRViewer',
        showInComplete: true,
        position: 'bar',
    },
    {
        id: 'Length',
        type: 'tool',
        label: 'Length',
        icon: 'tool-length',
        mode: 'normal',
        action: 'onChangeTool',
        showInComplete: false,
        position: 'more',
    },
    {
        id: 'Angle',
        type: 'tool',
        label: 'Angle',
        icon: 'tool-angle',
        mode: 'normal',
        action: 'onChangeTool',
        showInComplete: false,
        position: 'more',
    },
    {
        id: 'EllipticalRoi',
        type: 'tool',
        label: 'Ellipse',
        icon: 'tool-elipse',
        mode: 'normal',
        action: 'onChangeTool',
        showInComplete: false,
        position: 'more',
    },
    {
        id: 'RectangleRoi',
        type: 'tool',
        label: 'Rectangle',
        icon: 'tool-rectangle',
        mode: 'normal',
        action: 'onChangeTool',
        showInComplete: false,
        position: 'more',
    },
    {
        id: 'ArrowAnnotate',
        type: 'tool',
        label: 'Arrow',
        icon: 'tool-annotate',
        mode: 'normal',
        action: 'onChangeTool',
        showInComplete: false,
        position: 'more',
    },
    {
        id: 'Cine',
        type: 'action',
        label: 'Cine',
        icon: 'tool-cine',
        mode: 'normal',
        action: 'onCineExecute',
        showInComplete: false,
        position: 'more',
    },
    {
        id: 'StackScroll',
        type: 'toggle',
        label: 'Stack Scroll',
        icon: 'tool-stack-scroll',
        mode: 'normal',
        action: 'onChangeTool',
        showInComplete: true,
        position: 'more'
    },
    {
        id: 'Eraser',
        type: 'tool',
        label: 'Eraser',
        icon: 'tool-erase',
        mode: 'normal',
        action: 'onChangeTool',
        showInComplete: false,
        position: 'more',
    },
    {
        id: 'Magnify',
        type: 'toggle',
        label: 'Magnify',
        icon: 'tool-magnify',
        mode: 'normal',
        action: 'onChangeTool',
        showInComplete: true,
        position: 'more',
    },
    {
        id: 'RotateRight',
        type: 'action',
        label: 'Rotate Right',
        icon: 'tool-rotate-right',
        mode: 'normal',
        action: 'onToolExecute',
        showInComplete: true,
        position: 'more',
    },
    {
        id: 'FlipH',
        type: 'action',
        label: 'Flip Horizontally',
        icon: 'tool-flip-horizontal',
        mode: 'normal',
        action: 'onToolExecute',
        showInComplete: true,
        position: 'more',
    },
    {
        id: 'FlipV',
        type: 'action',
        label: 'Flip Vertically',
        icon: 'tool-flip-vertical',
        mode: 'normal',
        action: 'onToolExecute',
        showInComplete: true,
        position: 'more',
    },
    {
        id: 'MPRCrosshairs',
        type: 'toggle',
        label: 'Crosshairs',
        icon: 'tool-crosshair',
        mode: 'mpr',
        action: 'onChangeTool',
        showInComplete: true,
        position: 'bar',
    },
    {
        id: 'MPRClose',
        type: 'action',
        label: 'Close MPR',
        icon: 'tool-exit',
        mode: 'mpr',
        action: 'onMPRViewerClose',
        showInComplete: true,
        position: 'bar',
    },
]

const resetTool = {
    id: 'Reset',
    type: 'action',
    label: 'Reset',
    icon: 'tool-reset',
    mode: 'normal',
    action: 'onResetImageViewer',
    showInComplete: true,
    position: 'more',
}

function Toolbar(props) {
    const {
        complete,
        toolList,
        currentTool,
        changeCurrentTool,
        changeHangingLayout,
        changeCineTool,
        focusImageViewerIndex,
        showImageList,
        setMPRModeImageId,
        mprModeImageId,
        imageList
    } = props;

    const onChangeTool = (selectedTool) => {
        changeCurrentTool(selectedTool);
    }

    const onResetImageViewer = () => {
        // call to fix freehand release issue
        onChangeTool('Pan');
        setTimeout(() => changeHangingLayout('reset'), 500);
    }

    const onMPRViewer = () => {
        const rowCol = focusImageViewerIndex ? focusImageViewerIndex.split('_') : [0, 0];
        const mprImageId = showImageList[Number(rowCol[0])][Number(rowCol[1])];
        const mprImageInfo = imageList.find((v) => v.id === mprImageId);
        if (mprImageInfo && mprImageInfo.isMprAvailable) {
            setMPRModeImageId(mprImageId);
        } else {
            NotificationManager.error("The MPR mode could not be applied.");
        }
    }

    const onMPRViewerClose = () => {
        setMPRModeImageId(null);
        changeCurrentTool('Pan');
    }

    const onToolExecute = (toolName) => {
        if (!focusImageViewerIndex) return;
        const viewerComponent = document.querySelector(`[data-index="${focusImageViewerIndex}"]`);
        if (!viewerComponent) return;
        const element = viewerComponent.querySelector("[class='dicom']");
        if (!element) return;
        const viewport = cornerstone.getViewport(element);
        if (toolName === 'RotateRight') {
            viewport.rotation += 90;
        } else if (toolName === 'FlipH') {
            viewport.hflip = !viewport.hflip;
        } else if (toolName === 'FlipV') {
            viewport.vflip = !viewport.vflip;
        }
        cornerstone.setViewport(element, viewport);
        cornerstone.invalidate(element);
    }

    const onCineExecute = () => {
        changeCineTool(true);
    }


    const toolActions = {onChangeTool, onToolExecute, onResetImageViewer, onMPRViewer, onMPRViewerClose, onCineExecute};
    let showToolList;
    if (!!mprModeImageId) {
        // mpr mode
        showToolList = siteToolList.filter((v) => v.mode === 'mpr');
    } else {
        showToolList = siteToolList.filter((v) => v.mode === 'normal').filter((v) => toolList.includes(v.id)).filter((v) => !complete ? true : v.showInComplete);
    }
    // calc toolbar width
    const toolbarWidth = (window.innerWidth / 2) - 300;

    const toolIconWidth = 45;
    let toolbarToolList = showToolList.filter((v) => v.position === 'bar');
    let moreToolList = showToolList.filter((v) => v.position !== 'bar');
    if (toolbarWidth <= toolIconWidth * toolbarToolList.length) {
        // need group tool
        const moveMoreTools = toolbarToolList.splice(Math.floor(toolbarWidth / toolIconWidth) - 1);
        moreToolList = [...moveMoreTools, ...moreToolList];
    }
    moreToolList.unshift(resetTool);
    return (
        <div className={'tool-container'}>
            {
                toolbarToolList.map((v) => (
                    v.id === 'Wwwc' ? <WindowLevelButton onChangeTool={onChangeTool} key={v.id}/> :
                    <ToolbarButton
                        key={v.id}
                        id={v.id}
                        type={v.type}
                        isActive={currentTool === v.id}
                        onInteraction={(param) => toolActions[v.action] && toolActions[v.action](v.id, param)}
                        icon={v.icon}
                        label={v.label}
                    />
                ))
            }
            {
                moreToolList.length > 0 &&
                <ToolbarSplitButton
                    currentTool={currentTool}
                    items={moreToolList}
                    onInteraction={(item) => toolActions[item.action] && toolActions[item.action](item.id)}
                />
            }
            {
                toolList.indexOf('Grid') !== -1 && !mprModeImageId && <GridToolButton/>
            }
        </div>
    )
}


// map state to props
const mapStateToProps = (state) => {
    return {
        toolList: state.testView.toolList,
        currentTool: state.testView.currentTool,
        focusImageViewerIndex: state.testView.focusImageViewerIndex,
        showImageList: state.testView.showImageList,
        mprModeImageId: state.testView.mprModeImageId,
        imageList: state.testView.imageList,
    };
};

export default connect(mapStateToProps, {
    changeHangingLayout,
    changeCurrentTool,
    setMPRModeImageId,
    changeCineTool,
})(Toolbar);

