import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import classnames from 'classnames';
import cornerstone from "cornerstone-core";
import ToolbarSplitButton from "Components/Toolbar/ToolbarSplitButton";

export const WLList = [
    {
        groupId: '',
        items: [
            {hotkey: '0', title: 'Full dynamic range', ww: null, wl: null},
        ]
    }, {
        groupId: 'Head and neck',
        items: [
            {hotkey: '1', title: 'Brain', ww: 80, wl: 40},
            {hotkey: '2', title: 'Subdural', ww: 300, wl: 70},
            {hotkey: '3', title: 'Stroke', ww: 30, wl: 30},
            {hotkey: '4', title: 'Soft tissues', ww: 400, wl: 30},
        ]
    }, {
        groupId: 'Chest',
        items: [
            {hotkey: '5', title: 'Lungs', ww: 1500, wl: -600},
            {hotkey: '6', title: 'Mediastinum', ww: 350, wl: 50}
        ]
    }, {
        groupId: 'Abdomen',
        items: [
            {hotkey: '7', title: 'Soft tissues', ww: 400, wl: 50},
            {hotkey: '8', title: 'Liver', ww: 150, wl: 30}
        ]
    }, {
        groupId: 'Spine',
        items: [
            {hotkey: '9', title: 'Soft tissues', ww: 250, wl: 50},
            {hotkey: '`', title: 'Bone', ww: 1800, wl: 400}
        ]
    }
]

export const changeWindowLevel = (focusImageViewerIndex, ww, wl) => {
    if (!focusImageViewerIndex) return;
    const viewerComponent = document.querySelector(`[data-index="${focusImageViewerIndex}"]`);
    if (!viewerComponent) return;
    const element = viewerComponent.querySelector('[class="dicom"]');
    if (!element) return;
    const viewport = cornerstone.getViewport(element);
    const voiLutModuleInfo = cornerstone.metaData.get('voiLutModule', viewerComponent.dataset.url);
    const imagePixelModule = cornerstone.metaData.get('imagePixelModule', viewerComponent.dataset.url);
    if(imagePixelModule.pngFileBit !== 'Png16bit') {
        if(ww === null && wl === null) {  // Full dynamic range
            return;
        }
        viewport.voi.windowWidth = Number((ww * 255 / (voiLutModuleInfo?.windowWidth[0] || 255)).toFixed(3));
        viewport.voi.windowCenter = Number((wl * 128 / (voiLutModuleInfo?.windowCenter[0] || 128)).toFixed(3));
    } else {
        if(ww === null && wl === null) {  // Full dynamic range
            ww = imagePixelModule.largestPixelValue - imagePixelModule.smallestPixelValue;
            wl = imagePixelModule.largestPixelValue - (ww / 2);
        }
        viewport.voi.windowWidth = ww;
        viewport.voi.windowCenter = wl;
    }
    cornerstone.setViewport(element, viewport);
    cornerstone.invalidate(element);
}

const WindowLevelMenuItem = ({id, groupTitle, title, subtitle, isSelected, index}) => {
    if (groupTitle) {
        return (
            <div className={'wl-group-text'}>{groupTitle}</div>
        )
    } else if (title){
        return (
            <div className={classnames('wl-item', isSelected && 'bg-primary-dark')}>
                <span>{title}</span>
                <span>{subtitle}</span>
                <span>{id}</span>
            </div>
        )
    } else {
        return null;
    }
};

const WindowLevelButton = ({onChangeTool}) => {
    const currentTool = useSelector((state) => state.testView.currentTool);
    const focusImageViewerIndex = useSelector((state) => state.testView.focusImageViewerIndex);
    const [items, setItems] = useState([]);

    useEffect(() => {
        const list = [
            {id: 'Wwwc', type: 'toggle', label: 'Window Level', icon: 'tool-window-level'}
        ];
        WLList.forEach((g) => {
            list.push({id: g.groupId, groupTitle: g.groupId, type: 'tool'});
           g.items.forEach((v) => {
               list.push(createWwwcPreset(v.hotkey, v.title, v.ww, v.wl))
           })
        });
        setItems(list);
    }, []);

    const createWwwcPreset = (preset, title, ww, wl) => {
        return {
            id: preset,
            title,
            subtitle: (ww && wl) ? `${ww} / ${wl}` : '',
            ww,
            wl,
            type: 'values',
        };
    }

    const onClickItem = (item) => {
        if (item.type !== 'values') {
            onChangeTool(item.id);
        } else {
            changeWindowLevel(focusImageViewerIndex, item.ww, item.wl);
        }
    }

    return (
        <ToolbarSplitButton
            currentTool={currentTool}
            secondary={{
                icon: 'chevron-down',
                isActive: true,
                tooltip: 'W/L Presets',
            }}
            items={items}
            renderer={WindowLevelMenuItem}
            onInteraction={(item) => onClickItem(item)}
        />
    )
}

export default WindowLevelButton;
