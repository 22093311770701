import React, {Component, useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import classNames from 'classnames';
import moment from 'moment';
import ImageBrowserItem from './ImageBrowserItem';

function ImageBrowser (props) {
    const imageList = useSelector((state) => state.testView.imageList);
    const isShowImageBrowser = useSelector((state) => state.testView.isShowImageBrowser);
    const testSetInfo = useSelector((state) => state.testView.testSetInfo);
    const [dateGroupList, setDateGroupList] = useState([]);

    useEffect(() => {
        const dateList = [];
        imageList.forEach((v) => {
            const i = dateList.findIndex((g) => g.date === v.metaData?.acquisitionDate);
            if(i === -1) {
                dateList.push({
                    date: v.metaData?.acquisitionDate,
                    modalityList: v.metaData?.modality ? [v.metaData?.modality] : [],
                    desc: v.metaData?.studyDescription,
                    stack: v.stack_count,
                    list: [v],
                    isCollapse: false
                })
            } else {
                dateList[i].desc = dateList[i].modality || v.metaData?.studyDescription;
                dateList[i].stack += v.stack_count;
                if(v.metaData?.modality && !dateList[i].modalityList.includes(v.metaData?.modality)) {
                    dateList[i].modalityList.push(v.metaData?.modality);
                }
                dateList[i].list.push(v);
            }
        });
        dateList.sort((a, b) => {
            const aDate = a.date || '0';
            const bDate = b.date || '0';
            return aDate - bDate;
        });
        setDateGroupList(dateList);
    }, [imageList]);

    const onCollapse = (i) => {
        dateGroupList[i].isCollapse = !dateGroupList[i].isCollapse;
        setDateGroupList([...dateGroupList]);
    }

    const renderDateGroup = (info, index) => {
        return (
            <React.Fragment key={index}>
                <div className={classNames('image-group-info', {'group-collapse': info.isCollapse})} onClick={() => onCollapse(index)}>
                    <div className={'d-flex flex-row justify-content-between align-items-center'}>
                        <span className={'text-white'}>{info.date ? moment(info.date).format('DD-MMM-YYYY') : 'No Date'}</span>
                        <div className={'d-flex flex-row align-items-center'}>
                            <svg className="text-blue-300" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" transform="translate(1 1)">
                                    <path d="M6 17H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h15a1 1 0 0 1 1 1v5"/>
                                    <rect width="17" height="17" x="6" y="6" rx="1"/>
                                </g>
                            </svg>
                            <span className={'ms-2'}>{info.stack}</span>
                        </div>
                    </div>
                    <div className={'d-flex flex-row align-items-center pt-2'}>
                        <span className={'fs-20 me-3'}>{info.modalityList.join("\\")}</span>
                        <span className={'group-desc'}>{info.desc}</span>
                    </div>
                </div>
                {
                    !info.isCollapse && info.list.map((v, i) => (
                        <ImageBrowserItem item={v} key={i}/>
                    ))
                }
            </React.Fragment>
        )
    }

    return(
        <div className={'image-browser ' + (isShowImageBrowser ? '' : 'hide')}>
            {
                (testSetInfo?.name || testSetInfo?.code) &&
                <div className={'px-2 py-1 text-white'} style={{backgroundColor: '#090c29', direction: 'ltr'}}>
                    {testSetInfo?.name && <div className={'fs-20'}>{testSetInfo?.name}</div>}
                    {testSetInfo?.code && <div className={'fs-13'}>{testSetInfo?.code}</div>}
                </div>
            }
            {
                dateGroupList.map((v, i) => renderDateGroup(v, i))
            }
        </div>
    )
}

export default ImageBrowser;