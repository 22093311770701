/**
 * App Redux Action Types
 */

// Auth Actions
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE';
export const USER_COMPLETED_COUNT = 'USER_COMPLETED_COUNT';
export const USER_COMPLETED_POINT = 'USER_COMPLETED_POINT';

//Test View Actions
export const TEST_VIEW_SET_IMAGE_LIST = 'TEST_VIEW_SET_IMAGE_LIST';
export const TEST_VIEW_CHANGE_IMAGE_LIST = 'TEST_VIEW_CHANGE_IMAGE_LIST';
export const TEST_VIEW_SET_SHOW_IMAGE_LIST = 'TEST_VIEW_SET_SHOW_IMAGE_LIST';
export const TEST_VIEW_SET_INITIAL_ZOOM_LEVEL = 'TEST_VIEW_SET_INITIAL_ZOOM_LEVEL';
export const TEST_VIEW_SET_SHOW_IMAGE_BROWSER = 'TEST_VIEW_SET_SHOW_IMAGE_BROWSER';
export const TEST_VIEW_SET_HANGING_TYPE = 'TEST_VIEW_SET_HANGING_TYPE';
export const TEST_VIEW_SET_RESET_ID = 'TEST_VIEW_SET_RESET_ID';
export const TEST_VIEW_SET_CASE_DENSITY = 'TEST_VIEW_SET_CASE_DENSITY';
export const TEST_VIEW_SET_CURRENT_TOOL = 'TEST_VIEW_SET_CURRENT_TOOL';
export const TEST_VIEW_SET_THICKNESS_TYPE = 'TEST_VIEW_SET_THICKNESS_TYPE';
export const TEST_VIEW_FULL_IMAGEVIEWER = 'TEST_VIEW_FULL_IMAGEVIEWER';
export const TEST_VIEW_TEST_OBJ_INFO = 'TEST_VIEW_TEST_OBJ_INFO';
export const TEST_VIEW_FOCUS_VIEWER_INDEX = 'TEST_VIEW_FOCUS_VIEWER_INDEX';

//Test View Component Actions
export const TEST_VIEW_COMP_SET_BREAST_QUALITY = 'TEST_VIEW_COMP_SET_BREAST_QUALITY';
export const TEST_VIEW_COMP_ENABLE_CINE_TOOL = 'TEST_VIEW_COMP_ENABLE_CINE_TOOL';

export const TEST_VIEW_SET_MPR_MODE_IMAGE_ID = 'TEST_VIEW_SET_MPR_MODE_IMAGE_ID';