import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import ToolbarTooltip from './ToolbarTooltip';
import ToolbarButton from "./ToolbarButton";
import LocalIcon from "Components/LocalIcon";


const baseClasses = {
    Button: 'd-flex align-items-center rounded-3 border-transparent cursor-pointer group/button',
    Primary: 'h-100 border-l-2 border-t-2 border-b-2 rounded-tl-md rounded-bl-md group/primary !pl-2 !py-2',
    Secondary: 'h-100 d-flex align-items-center justify-content-center rounded-tr-md rounded-br-md w-4 border-2 border-transparent group/secondary',
    SecondaryIcon: 'w-4 h-100 stroke-1',
    Separator: 'border-l py-2.5',
    Content: 'tool-list-content',
};

const classes = {
    Button: ({isExpanded, primary}) =>
        classNames(
            baseClasses.Button,
            !isExpanded &&
            !primary.isActive &&
            'hover:!bg-primary-dark hover:border-primary-dark'
        ),
    Interface: 'h-100 d-flex flex-row align-items-center',
    Primary: ({primary, isExpanded}) =>
        classNames(
            baseClasses.Primary,
            primary.isActive
                ? isExpanded
                    ? 'border-primary-dark !bg-primary-dark hover:border-primary-dark !text-primary-light'
                    : `${
                        primary.isToggle
                            ? 'border-secondary-dark bg-secondary-light'
                            : 'border-primary-light bg-primary-light'
                    }
            border-2 rounded-md !p-2` // Full, rounded border with less right padding when active.
                : `focus:!text-black focus:!rounded-md focus:!border-primary-light focus:!bg-primary-light
        ${
                    isExpanded
                        ? 'border-primary-dark bg-primary-dark !text-primary-light'
                        : 'border-secondary-dark bg-secondary-dark group-hover/button:border-primary-dark group-hover/button:text-primary-light hover:!bg-primary-dark hover:border-primary-dark focus:!text-black'
                }
        `
        ),
    Secondary: ({isExpanded, primary}) =>
        classNames(
            baseClasses.Secondary,
            isExpanded
                ? 'bg-primary-light !rounded-tr-md !rounded-br-md'
                : primary.isActive
                    ? 'bg-secondary-dark'
                    : 'hover:bg-primary-dark bg-secondary-dark group-hover/button:border-primary-dark'
        ),
    SecondaryIcon: ({isExpanded}) =>
        classNames(
            baseClasses.SecondaryIcon,
            isExpanded
                ? 'text-primary-dark'
                : 'text-[#348cfd] group-hover/secondary:text-primary-light'
        ),
    Separator: ({primary, isExpanded, isHovering}) =>
        classNames(
            baseClasses.Separator,
            isHovering || isExpanded || primary.isActive
                ? 'border-transparent'
                : 'border-primary-active'
        ),
    Content: ({isExpanded}) => classNames(baseClasses.Content, isExpanded ? 'd-block' : 'd-none'),
};

const ListMenu = ({items = [], onClickItem, renderer}) => {
    const [selectedIndex, setSelectedIndex] = useState(null);
    const DefaultListItemRenderer = ({type, icon, label, id}) => {
        const isActive = type === 'toggle';
        return (
            <div
                className={classNames(
                    isActive && 'bg-primary-dark',
                    isActive
                        ? 'text-[#348CFD]'
                        : 'text-common-bright'
                )}
            >
                {
                    icon && (
                        <span className="me-3">
                            <LocalIcon name={icon}/>
                        </span>
                    )
                }
                <span className="me-4">{label}</span>
            </div>
        );
    };

    const ListItem = ({item, index, isSelected, onClickItem}) => {
        const onClickHandler = () => {
            setSelectedIndex(index);
            onClickItem(item, index);
        };
        const listItemRenderer = renderer || DefaultListItemRenderer;
        return (
            <div className={'tool-list-item cursor-pointer'} onClick={onClickHandler}>
                {listItemRenderer({...item, index, isSelected})}
            </div>
        );
    };

    return (
        <div className="d-flex flex-column rounded-3 bg-secondary-dark pt-2 pb-2">
            {items.map((item, index) => {
                return (
                    <ListItem
                        key={`ListItem${index}`}
                        index={index}
                        isSelected={selectedIndex === index}
                        item={item}
                        onClickItem={onClickItem}
                    />
                );
            })}
        </div>
    );
};

const SplitButton = ({ secondary, items: _items, onInteraction, currentTool, renderer}) => {
    const [primary, setPrimary] = useState(null);
    const [items, setItems] = useState([]);
    const [isHovering, setIsHovering] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);


    useEffect(() => {
        if(!primary) {
            setPrimary(_items[0]);
        }
        setItems(_items)
    }, [_items]);

    if(!primary) return null;
    const onSecondaryClickHandler = () => setIsExpanded(!isExpanded);
    const onMouseEnterHandler = () => setIsHovering(true);
    const onMouseLeaveHandler = () => setIsHovering(false);
    const outsideClickHandler = () => setIsExpanded(false);

    const isPrimaryToggle = primary.type === 'toggle';
    const isPrimaryActive = true;

    const primaryButtonClassName = classes.Primary({
        isExpanded,
        primary: {isActive: isPrimaryActive, isToggle: isPrimaryToggle},
    });

    const onClickItem = (item, index) => {
        onInteraction(item);
        item.icon && setPrimary(item);
        setIsExpanded(false);
    }

    return (
        <OutsideClickHandler onOutsideClick={outsideClickHandler}>
            <div className="position-relative me-1">
                <div
                    className={classes.Button({
                        isExpanded,
                        primary: {isActive: isPrimaryActive},
                    })}
                    style={{height: '40px'}}
                    onMouseEnter={onMouseEnterHandler}
                    onMouseLeave={onMouseLeaveHandler}
                >
                    <div className={classes.Interface}>
                        <div onClick={outsideClickHandler}>
                            <ToolbarButton
                                key={primary.id}
                                {...primary}
                                isActive={currentTool === primary.id}
                                onInteraction={() => onInteraction(primary)}
                                // All rounding is taken care of by className
                                rounded="none"
                                className={'m-0'}
                                data-tool={primary.id}
                            />
                        </div>
                        <div className={'tool-split-line'}/>
                        <div
                            className={'tool-more-btn'}
                            onClick={onSecondaryClickHandler}
                        >
                            <ToolbarTooltip
                                isDisabled={isExpanded || !secondary.tooltip}
                                content={secondary.tooltip}
                                className="h-100 m-0"
                            >
                                <LocalIcon
                                    name={secondary.icon}
                                    className={classes.SecondaryIcon({
                                        isExpanded,
                                        primary: {isActive: isPrimaryActive},
                                    })}
                                />
                            </ToolbarTooltip>
                        </div>
                    </div>
                </div>
                {/* EXPANDED LIST OF OPTIONS */}
                <div
                    className={classes.Content({isExpanded})}
                >
                    <ListMenu
                        items={items.filter((v) => v.id !== primary.id)}
                        onClickItem={onClickItem}
                        renderer={renderer}
                    />
                </div>
            </div>
        </OutsideClickHandler>
    );
};

SplitButton.defaultProps = {
    isRadio: false,
    isAction: false,
    primary: {
        label: null,
        tooltip: null,
    },
    secondary: {
        icon: 'chevron-down',
        label: null,
        isActive: true,
        tooltip: 'More Tools',
    },
    items: [],
    renderer: null,
};

SplitButton.propTypes = {
    primary: PropTypes.shape({
        id: PropTypes.string,
        icon: PropTypes.string,
        label: PropTypes.string,
        type: PropTypes.oneOf(['tool', 'action', 'toggle']),
        tooltip: PropTypes.string,
    }),
    secondary: PropTypes.shape({
        id: PropTypes.string,
        icon: PropTypes.string,
        label: PropTypes.string,
        tooltip: PropTypes.string,
        isActive: PropTypes.bool,
    }),
    renderer: PropTypes.func,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            icon: PropTypes.string,
            label: PropTypes.string,
            type: PropTypes.oneOf(['tool', 'action', 'toggle', 'values']).isRequired,
            tooltip: PropTypes.string,
            isActive: PropTypes.bool,
        })
    ),
    /** Callback function to inform toolbarService of important events */
    onInteraction: PropTypes.func.isRequired,
};

export default SplitButton;
