import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {debounce} from 'lodash';
import cornerstoneTools from 'cornerstone-tools';
import LocalIcon from 'Components/LocalIcon';
import Slider from '@mui/material/Slider';
import Tooltip from 'Components/Toolbar/ToolbarTooltip';
import {changeCineTool} from 'Store/Actions';
import {useDispatch, useSelector} from "react-redux";


const CinePlayer = ({
                        element,
                        className,
                        minFrameRate,
                        maxFrameRate,
                        stepFrameRate,
                        frameRate: defaultFrameRate,
                        onFrameRateChange,
                    }) => {
    const dispatch = useDispatch();
    const enableCineTool = useSelector((state) => state.testView.enableCineTool);
    const [isPlaying, setIsPlaying] = useState(false);
    const [frameRate, setFrameRate] = useState(defaultFrameRate);
    const [frameSlideValue, setFrameSlideValue] = useState(defaultFrameRate);
    const debouncedSetFrameRate = debounce(setFrameRate, 300);
    const getPlayPauseIconName = () => (isPlaying ? 'icon-pause' : 'icon-play');

    useEffect(() => {
        if(isPlaying) {
            cornerstoneTools.playClip(element, frameRate);
        }
    }, [frameRate])

    useEffect(() => {
        if(!enableCineTool && element) {
            cornerstoneTools.stopClip(element);
            setIsPlaying(false);
        }
    }, [enableCineTool]);

    const handleSetFrameRate = (newFrameRate) => {
        if (newFrameRate < minFrameRate || newFrameRate > maxFrameRate) {
            return;
        }
        setFrameSlideValue(newFrameRate);
        debouncedSetFrameRate(newFrameRate);
    };

    const onCloseTool = () => {
        dispatch(changeCineTool(false));
    }

    const onPlayStack = () => {
        const play = !isPlaying;
        setIsPlaying(play);
        if(play) {
            cornerstoneTools.playClip(element, frameRate);
        } else {
            cornerstoneTools.stopClip(element);
        }
    }

    if(!enableCineTool) return null;
    return (
        <div className={'cine-player'}>
            <LocalIcon
                name={getPlayPauseIconName()}
                className="cursor-pointer text-white cine-btn me-2"
                onClick={onPlayStack}
            />
            <Tooltip
                position="top"
                className="cine-fps-range-tooltip"
                tight={true}
                content={
                    <Slider
                        className={'cine-slider'}
                        size={'small'}
                        value={frameSlideValue}
                        min={minFrameRate}
                        max={maxFrameRate}
                        step={stepFrameRate}
                        onChange={(e, value) => handleSetFrameRate(value)}
                    />
                }
            >
                <div className="cine-fps-content">
                    <div
                        className={'cine-fps-btn cursor-pointer'}
                        onClick={() => handleSetFrameRate(frameRate - 1)}
                    >
                        <LocalIcon name="arrow-left-small"/>
                    </div>
                    <div className="text-white text-center fs-14">
                        {`${frameSlideValue} FPS`}
                    </div>
                    <div
                        className={'cine-fps-btn cursor-pointer'}
                        onClick={() => handleSetFrameRate(frameRate + 1)}
                    >
                        <LocalIcon name="arrow-right-small"/>
                    </div>
                </div>
            </Tooltip>
            <LocalIcon
                name="icon-close"
                className="cursor-pointer text-primary1 cine-btn ms-2"
                onClick={onCloseTool}
            />
        </div>
    );
};

const noop = () => {
};

CinePlayer.defaultProps = {
    minFrameRate: 1,
    maxFrameRate: 90,
    stepFrameRate: 1,
    frameRate: 24,
    onFrameRateChange: noop,
    onClose: noop,
};

CinePlayer.propTypes = {
    element: PropTypes.object,
    /** Minimum value for range slider */
    minFrameRate: PropTypes.number.isRequired,
    /** Maximum value for range slider */
    maxFrameRate: PropTypes.number.isRequired,
    /** Increment range slider can "step" in either direction */
    stepFrameRate: PropTypes.number.isRequired,
    frameRate: PropTypes.number.isRequired,
    onFrameRateChange: PropTypes.func,
    onClose: PropTypes.func,
};

export default CinePlayer;