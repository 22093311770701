import React, {useState, useEffect, useRef} from "react";
import cornerstone from "cornerstone-core";
import {
    View2D,
    getImageData,
    loadImageData,
    vtkInteractorStyleRotatableMPRCrosshairs,
    vtkSVGRotatableCrosshairsWidget,
    vtkInteractorStyleMPRWindowLevel,
} from 'react-vtkjs-viewport';
import vtkVolume from '@kitware/vtk.js/Rendering/Core/Volume';
import vtkVolumeMapper from '@kitware/vtk.js/Rendering/Core/VolumeMapper';
import _ from 'lodash';
import LoadingIndicator from "./LoadingIndicator";
import {useSelector} from "react-redux";

function loadDataset(imageIds, displaySetInstanceUid) {
    const imageDataObject = getImageData(imageIds, displaySetInstanceUid);
    loadImageData(imageDataObject);
    return imageDataObject;
}

let volumeApis = [];
const volumeCache = {};

function VTKViewer(props) {
    const currentTool = useSelector((state) => state.testView.currentTool);
    const [isLoading, setIsLoading] = useState(true);
    const [volumes, setVolumes] = useState([]);
    const [displayCrosshairs, setDisplayCrosshairs] = useState(true);

    useEffect(() => {
        volumeApis = [];
        initViewer();
    }, []);

    useEffect(() => {
        if(!isLoading) {
            if(currentTool === 'MPRCrosshairs') {
                enableCrosshairs();
            } else if(currentTool === 'Wwwc') {
                enableLevelTool();
            }
        }
    }, [currentTool])


    const initViewer = async ()  => {
        const imageIds = Array.from(Array(props.imageInfo.stack_count).keys()).map((v) => props.imageInfo.image_url_path + v);
        const ctImageDataObject = loadDataset(imageIds, props.imageInfo.id);
        if(!ctImageDataObject.isLoading && volumeCache[props.imageInfo.id]) {
            setVolumes([volumeCache[props.imageInfo.id]]);
            setTimeout(() => setIsLoading(false), 1000);
        }
        const onAllPixelDataInsertedCallback = () => {
            const ctImageData = ctImageDataObject.vtkImageData;

            const range = ctImageData
                .getPointData()
                .getScalars()
                .getRange();

            const mapper = vtkVolumeMapper.newInstance();
            const ctVol = vtkVolume.newInstance();
            const rgbTransferFunction = ctVol.getProperty().getRGBTransferFunction(0);

            mapper.setInputData(ctImageData);
            mapper.setMaximumSamplesPerRay(2000);
            // rgbTransferFunction.setRange(range[0], range[1]);

            const imagePixelModule = cornerstone.metaData.get('imagePixelModule', imageIds[0]);
            if(imagePixelModule.pngFileBit !== 'Png16bit') {
                rgbTransferFunction.setMappingRange(0, 255);
            } else {
                const voiLutModuleInfo = cornerstone.metaData.get('voiLutModule', imageIds[0]);
                const ww = voiLutModuleInfo.windowWidth[0];
                const wc = voiLutModuleInfo.windowCenter[0];
                rgbTransferFunction.setMappingRange(wc - ww / 2, wc + ww / 2);
            }
            ctVol.setMapper(mapper);
            volumeCache[props.imageInfo.id] = ctVol;

            // 추가
            ctVol.getProperty().setIndependentComponents(false);
            ctVol.getProperty().setInterpolationTypeToNearest();
            setVolumes([ctVol]);
            setIsLoading(false);
        };
        ctImageDataObject.onAllPixelDataInserted(onAllPixelDataInsertedCallback);
    }

    const enableCrosshairs = () => {
        volumeApis.forEach((api, apiIndex) => {
            api.addSVGWidget(
                vtkSVGRotatableCrosshairsWidget.newInstance(),
                'rotatableCrosshairsWidget'
            );

            const uid = api.uid;
            const istyle = vtkInteractorStyleRotatableMPRCrosshairs.newInstance();
            api.setInteractorStyle({
                istyle,
                configuration: { apis: volumeApis, apiIndex, uid },
            });

            api.svgWidgets.rotatableCrosshairsWidget.setApiIndex(apiIndex);
            api.svgWidgets.rotatableCrosshairsWidget.setApis(volumeApis);

            const mapper = api.volumes[0].getMapper();
            if (mapper.setBlendModeToMaximumIntensity) {
                mapper.setBlendModeToMaximumIntensity();
            }
            api.setSlabThickness(0.1);
        });
        // Initialise crosshairs
        volumeApis[0].svgWidgets.rotatableCrosshairsWidget.resetCrosshairs(volumeApis, 0);
    }


    const enableLevelTool = () => {
        function updateVOI(apis, windowWidth, windowCenter) {
            apis.forEach(api => {
                api.updateVOI(windowWidth, windowCenter);
            });
        }

        const throttledUpdateVOIs = _.throttle(updateVOI, 16, { trailing: true }); // ~ 60 fps

        const callbacks = {
            setOnLevelsChanged: ({ windowCenter, windowWidth }) => {
                volumeApis.forEach(api => {
                    const renderWindow = api.genericRenderWindow.getRenderWindow();
                    renderWindow.render();
                });
                throttledUpdateVOIs(volumeApis, windowWidth, windowCenter);
            },
        };

        volumeApis.forEach((api, apiIndex) => {
            const istyle = vtkInteractorStyleMPRWindowLevel.newInstance();

            api.setInteractorStyle({
                istyle,
                callbacks,
                configuration: { apis: volumeApis, apiIndex, uid: api.uid },
            });
        });
    }

    const toggleCrosshairs = () => {
        const shouldDisplayCrosshairs = !displayCrosshairs;
        volumeApis.forEach(api => {
            const {svgWidgetManager, svgWidgets} = api;
            svgWidgets.crosshairsWidget.setDisplay(shouldDisplayCrosshairs);

            svgWidgetManager.render();
        });
        setDisplayCrosshairs(shouldDisplayCrosshairs);
    };

    const handleSlabThicknessChange = (evt) => {
        const value = evt.target.value;
        const valueInMM = value / 10;
        const apis = volumeApis;

        apis.forEach(api => {
            const renderWindow = api.genericRenderWindow.getRenderWindow();

            api.setSlabThickness(valueInMM);
            renderWindow.render();
        });
    }

    const onCreate = (createdApi, index) => {
        volumeApis[index] = createdApi;
        const renderWindow = createdApi.genericRenderWindow.getRenderWindow();
        renderWindow.render();
        if(volumeApis.length === 3) {
            volumeApis.forEach((api, apiIndex) => {
                const istyle = vtkInteractorStyleMPRWindowLevel.newInstance();
                api.setInteractorStyle({
                    istyle,
                    configuration: {apis: volumeApis, apiIndex},
                });
            });
        }
    }

    if (isLoading) {
        return <LoadingIndicator type={"image"}/>
    }
    return (
        <div
            ref={props.dndRef}
            className={'mpr-image'}
            id={"image" + props.imageInfo.id}
            data-image-id={props.imageInfo.id}
            data-url={props.imageInfo.id}
            data-index={props.index}
            data-stack={props.imageInfo.stack_count}
        >
            <div>
                <View2D
                    volumes={volumes}
                    onCreated={(api) => onCreate(api, 0)}
                    orientation={{sliceNormal: [0, 0, 1], viewUp: [0, -1, 0]}}
                />
            </div>
            <div>
                <View2D
                    volumes={volumes}
                    onCreated={(api) => onCreate(api, 1)}
                    orientation={{sliceNormal: [-1, 0, 0], viewUp: [0, 0, 1]}}
                />
            </div>
            <div>
                <View2D
                    volumes={volumes}
                    onCreated={(api) => onCreate(api, 2)}
                    orientation={{sliceNormal: [0, 1, 0], viewUp: [0, 0, 1]}}
                />
            </div>
        </div>
    )
}

export default VTKViewer;