import React, {useState, useEffect} from "react";
import {Input, Label} from "reactstrap";
import {Button} from "@mui/material";

function MarkerPopupQuiz(props) {
    const [selectedLesionList, setSelectedLesionList] = useState(props.markData.lesionList || {});

    const handleClosePopup = (type) => {
        const {attempts_id, test_cases_id, markData, isPostTest, ratings, onClose, popupCancelHandler, popupDeleteHandler, popupSaveHandler} = props;
        onClose();
        switch (type) {
            case 'cancel':
            case 'ok':
                popupCancelHandler();
                break;
            case 'delete':
                if (!window.confirm('Are you sure you want to delete this mark?')) break;
                popupDeleteHandler(markData.id);
                break;
            case 'save':
                let data = {
                    id: markData.id,
                    marker_tool_type: markData.marker_tool_type,
                    attempt_id: attempts_id,
                    test_case_id: test_cases_id,
                    rating: 3,
                    answer_lesion_list: JSON.stringify(selectedLesionList),
                    isNew: markData.isNew,
                    is_post_test: isPostTest
                };
                if (data.marker_tool_type === 'Marker') {
                    data.marker_data = JSON.stringify({x: markData.handles.end.x, y: markData.handles.end.y})
                } else if (data.marker_tool_type === 'MarkerFreehand') {
                    data.marker_data = JSON.stringify(markData.handles).replace(/-?\d+\.\d+/g, function (x) {
                        return parseFloat(x).toFixed(2)
                    });
                }
                popupSaveHandler(data);
                break;
            default:
                break;
        }
    }

    return (
        <div id="cover" onClick={(e) => handleClosePopup('cancel')}>
            <div id="mark-details" style={{width: 432}} onClick={(e) => e.stopPropagation()}>
                <form>
                    <div>
                        <Input
                            type={'text'}
                            style={{backgroundColor: 'transparent', color: 'yellow'}}
                            placeholder={'Please input comment'}
                            value={selectedLesionList && selectedLesionList['Comment']}
                            onChange={(e) => setSelectedLesionList({'Comment': e.target.value})}
                        />
                    </div>
                    <div className="actions mt-5">
                        {
                            props.complete ?
                                <div className="right">
                                    <Button variant="contained" className="ok" onClick={() => handleClosePopup('ok')}>&nbsp;&nbsp;Ok&nbsp;&nbsp;</Button>
                                </div> :
                                <div className="right">
                                    {
                                        !props.markData.isNew ?
                                            <Button variant="contained" className="me-15 delete" onClick={() => handleClosePopup('delete')}>Delete</Button> : null
                                    }
                                    <Button variant="contained" className="save" onClick={() => handleClosePopup('save')}>Save</Button>
                                </div>
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}

export default MarkerPopupQuiz;