/* eslint-disable */
import { useEffect } from 'react';
import * as Apis from 'Api';
import AuditDataLoggerConfig from "./AuditDataLoggerConfig";

const timeTrackerScriptAddress = '/apptimerecorder.min.js';
const PlatformTimer = () => {

    useEffect(() => {

        const script = document.createElement('script')
        script.setAttribute('src', timeTrackerScriptAddress)
        script.id = 'AppTimeRecorder'
        script.addEventListener('load', () => {

            var config = {
                trackBy: 'seconds',
                developerMode: false,
                trackHistoryChange: false,
                callback: function(dataTo) {
                    const data = {
                        log_type: dataTo.log_type,
                        page_title: dataTo.title,
                        current_action: dataTo.current_action,
                        action_location: dataTo.URL,
                        activity_start_action: dataTo.activity_start_action,
                        activity_end_action: dataTo.activity_end_action,
                        activity_duration: dataTo.trackingType==='atr' ? dataTo.timeOnPageByDuration : dataTo.timeTakenByDuration,  //trackingType: atr or (activity:mainActivity,subActivity).
                        labels: dataTo,

                    }

                    if( typeof dataTo.current_action !== 'undefined') {
                        if (AuditDataLoggerConfig.shouldPushLog) {
                            Apis.pushLog(data);
                        }

                    }



                }
            };

            if (AppTimeRecorder) {
                // IF this was not a `var` it will not be a global scoped variable
                var Atr = new AppTimeRecorder(config);
                const customData = {
                    activity_start_action: 'App Entry URL',
                };
                Atr.setCustomData(customData);
                window.Atr = Atr;
            }

        })
        document.body.appendChild(script)
    }, [])

    return null;
};

export default PlatformTimer;

