import React, {Component} from 'react'
import {RadioGroup, Tabs, Tab} from "@mui/material";
import {QuestionLabel, QuestionRadio, QuestionCheckbox, RatingRadio, RatingLabel, CheckboxTooltip, QuestionInput} from 'Components/SideQuestionComponents';
import * as Apis from 'Api';
import {NotificationManager} from "react-notifications";

const question = [
    {
        id: 'mriQ1',
        range: 'all',
        label: '1. Background Enhancement:',
        options: ['Minimal', 'Mild', 'Moderate', 'Marked'],
    },
    {
        id: 'mriQ2',
        range: 'eachBreast',
        label: '2. Axilla',
        options: ['Normal', 'Abnormal']
    },
    {
        id: 'mriQ3',
        range: 'eachBreast',
        label: '3. Extra breast findings:',
        options: ['Yes', 'No'],
        child: ['Lungs', 'Liver', 'Bones']
    },
    {
        id: 'mriQ4',
        range: 'eachBreast',
        label: '4. Management suggestion',
        options: ['Unifocal carcinoma/work up complete', 'Area identified for second look ultrasound in breast', 'Second look ultrasound axilla', 'Further investigation for distant disease', 'Benign findings only']
    },
]

export default class BreastEDMRIQuestions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            answerValue: {},
            truthValue: {},
            selectedBreast: 'left',
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        Apis.getAttemptChestAnswer(this.props.attempts_id, this.props.test_case_id, this.props.isPostTest).then(resp => {
            this.setState({
                answerValue: resp.chest_answer,
                truthValue: resp.chest_truth,
            });
        }).catch(error => {

        });
    }

    saveChestAnswer() {
        if (!this.props.complete) {
            Apis.setAttemptChestAnswer(this.props.attempts_id, this.props.test_case_id, 0, this.state.answerValue, this.props.isPostTest).then(resp => {

            }).catch(error => {
                NotificationManager.error(error.response ? error.response.data.error.message : error.message);
            });
        }
    }

    getAnswerTruth(qId, isRootValue) {
        const {answerValue, truthValue, selectedBreast} = this.state;
        let questionAnswer, questionTruth;
        const questionObj = question.find((v) => v.id === qId);
        if(questionObj.range === 'eachBreast') {
            questionAnswer = answerValue[selectedBreast]?.[qId];
            questionTruth = truthValue[selectedBreast]?.[qId];
        } else {
            questionAnswer = answerValue[qId];
            questionTruth = truthValue[qId];
        }
        return {
            questionAnswer: isRootValue ? (questionAnswer?.value || '') : (questionAnswer || {}),
            questionTruth: isRootValue ? (questionTruth?.value || '') : (questionTruth || {})
        }
    }

    onChangeCheck(qId, childQId, value) {
        const {answerValue} = this.state;
        let checkValues = answerValue[qId][childQId];
        if (checkValues === undefined) checkValues = [];
        const valueIndex = checkValues.indexOf(value);
        if (valueIndex === -1) {
            // chestQ3a/chestQ3a has to be 0 or R, L
            if( qId === 'chestQ3a' && (childQId === 'q3cValues' || childQId.indexOf('q3bChest') === 0 || childQId.indexOf('q3cChest') === 0)) {
                if(value === '0') {
                    checkValues = [];
                } else {
                    const zeroIndex = checkValues.indexOf('0');
                    if(zeroIndex !== -1) checkValues.splice(zeroIndex, 1);
                }
            }

            checkValues.push(value);
        } else {
            checkValues.splice(valueIndex, 1);
        }
        answerValue[qId][childQId] = checkValues;
        this.setState({answerValue: {...answerValue}}, () => {
            this.saveChestAnswer();
        });
    }

    onChangeChildValue(qId, childQId, value) {
        const {answerValue, selectedBreast} = this.state;
        const questionObj = question.find((v) => v.id === qId);
        if(questionObj.range !== 'eachBreast') {
            answerValue[qId][childQId] = value;
        } else {
            answerValue[selectedBreast][qId][childQId] = value;
        }
        this.setState({answerValue: {...answerValue}}, () => {
            this.saveChestAnswer();
        });
    }

    onChangeQuestion(qId, value) {
        const {answerValue, selectedBreast} = this.state;
        const questionObj = question.find((v) => v.id === qId);
        if(questionObj.range !== 'eachBreast') {
            if (answerValue[qId] === undefined || answerValue[qId].value !== value) {
                answerValue[qId] = {};
            }
            answerValue[qId].value = value;
        } else {
            if (answerValue[selectedBreast] === undefined) {
                answerValue[selectedBreast] = {}
            }
            if(answerValue[selectedBreast][qId] === undefined || answerValue[selectedBreast][qId].value !== value)
            {
                answerValue[selectedBreast][qId] = {}
            }
            answerValue[selectedBreast][qId].value = value;
        }
        this.setState({answerValue: {...answerValue}}, () => {
            this.saveChestAnswer();
        });
    }

    renderOptionList(values, optionClass = '', disabled, qId, childQId, iconClass='chest-question-radio-icon') {
        const {questionTruth} = this.getAnswerTruth(qId);
        const qTruth = questionTruth[childQId] || '';
        return values.map((v, i) => {
            if(!v.hover) {
                return (
                    <QuestionLabel
                        key={i}
                        className={optionClass}
                        value={v}
                        control={
                            <QuestionRadio
                                icon={<span className={iconClass + ' ' + (qTruth === v ? 'truth-icon' : '')}/>}
                                checkedIcon={<span className={iconClass + ' checked ' + (qTruth === v ? 'truth-icon' : '')}/>}
                                disableRipple
                            />
                        }
                        label={v}
                        labelPlacement="end"
                        disabled={disabled}
                    />
                )
            } else {
                return (
                    <CheckboxTooltip title={v.hover} key={i}>
                        <QuestionLabel
                            className={optionClass}
                            value={v.value}
                            control={
                                <QuestionRadio
                                    icon={<span className={iconClass + ' ' + (qTruth === v.value ? 'truth-icon' : '')}/>}
                                    checkedIcon={<span className={iconClass + ' checked ' + (qTruth === v.value ? 'truth-icon' : '')}/>}
                                    disableRipple
                                />
                            }
                            label={v.value}
                            labelPlacement="end"
                            disabled={disabled}
                        />
                    </CheckboxTooltip>
                )
            }
        })
    }

    renderQuestion2Additional(questionObj, disabled) {
        const {questionAnswer, questionTruth} = this.getAnswerTruth(questionObj.id);
        return (
            <div>
                {
                    questionObj.child.map((v) => (
                        <div key={v.id} className={'ms-2'}>
                            <div className={'chest-question-sub-title fs-14'}>{v.label}</div>
                            {
                                v.options ?
                                    <RadioGroup
                                        className={'ms-3 mt-1 d-flex justify-content-center flex-column'}
                                        aria-label="position"
                                        name="position"
                                        value={questionAnswer[v.id] || ''}
                                        onChange={(event) => this.onChangeChildValue(questionObj.id, v.id, event.target.value)}
                                        disabled={false}
                                        row
                                    >
                                        {
                                            this.renderOptionList(v.options, '', disabled, questionObj.id, v.id)
                                        }
                                    </RadioGroup> :
                                    v.child.map((vv) => (
                                        <div key={vv.id} className={'ms-3'}>
                                            <div className={'chest-question-sub-title fs-14'}>{vv.label}</div>
                                            {
                                                vv.options ?
                                                    <RadioGroup
                                                        className={'ms-3 mt-1 d-flex justify-content-center flex-column'}
                                                        aria-label="position"
                                                        name="position"
                                                        value={questionAnswer[vv.id] || ''}
                                                        onChange={(event) => this.onChangeChildValue(questionObj.id, vv.id, event.target.value)}
                                                        disabled={false}
                                                        row
                                                    >
                                                        {
                                                            this.renderOptionList(vv.options, '', disabled, questionObj.id, vv.id)
                                                        }
                                                    </RadioGroup> :
                                                    <div>
                                                        <QuestionInput
                                                            id="q1OtherText"
                                                            className={'ms-3'}
                                                            value={questionAnswer[vv.id] || ''}
                                                            onChange={(e) => this.onChangeChildValue(questionObj.id, vv.id, e.target.value)}
                                                            disabled={disabled}
                                                        />
                                                        <span className={'text-red ms-1 fs-13'}>{questionTruth[vv.id] || ''}</span>
                                                    </div>
                                            }
                                        </div>
                                    ))
                            }
                        </div>
                    ))
                }
            </div>
        )
    }

    renderQuestion3Additional(questionObj, disabled) {
        const {questionAnswer, questionTruth} = this.getAnswerTruth(questionObj.id);
        return (
            <RadioGroup
                className={'ms-4 mt-1'}
                aria-label="position"
                name="position"
                value={questionAnswer['mriQ4Child'] !== undefined ? questionAnswer['mriQ4Child'] : ''}
                onChange={(event) => this.onChangeChildValue(questionObj.id, 'mriQ4Child', event.target.value)}
                row
                disabled={false}
            >
                {
                    this.renderOptionList(questionObj.child, '', disabled, questionObj.id, 'mriQ4Child')
                }
            </RadioGroup>
        )
    }

    renderAdditionalQuestion(questionObj, disabled) {
        const {questionAnswer, questionTruth} = this.getAnswerTruth(questionObj.id, true);
        if (questionObj.id === 'mriQ3' && (questionAnswer === 'Yes' || questionTruth === 'Yes')) {
            return this.renderQuestion3Additional(questionObj, disabled);
        }
    }

    renderQuestion(questionObj, disabled) {
        const {selectedBreast} = this.state;
        const qTruth = this.getAnswerTruth(questionObj.id, true).questionTruth;
        return (
            <div key={questionObj.id} className={'chest-question'}>
                {
                    questionObj.id === 'mriQ2' &&
                        <Tabs
                            style={{marginTop: -15, marginBottom: 10}}
                            value={selectedBreast === 'left' ? 0 : 1}
                            onChange={(e, value) => this.setState({selectedBreast: value === 0 ? 'left' : 'right' })}
                            variant={'fullWidth'}
                        >
                            <Tab label={'Left Breast'} />
                            <Tab label={'Right Breast'}/>
                        </Tabs>
                }
                <div className={'chest-question-title'}>
                    {questionObj.hover ? <CheckboxTooltip title={questionObj.hover}><span>{questionObj.label}</span></CheckboxTooltip>: questionObj.label}
                </div>
                <RadioGroup
                    className={'ms-4'}
                    aria-label="position"
                    name="position"
                    value={this.getAnswerTruth(questionObj.id, true).questionAnswer}
                    onChange={(event) => (disabled ? null : this.onChangeQuestion(questionObj.id, event.target.value))}
                    row
                    disabled={disabled}
                >
                    {
                        questionObj.options.map((v) => (
                            <QuestionLabel
                                key={v}
                                value={v}
                                control={
                                    <QuestionRadio
                                        icon={<span className={'chest-question-radio-icon ' + (qTruth === v ? 'truth-icon' : '')}/>}
                                        checkedIcon={<span className={'chest-question-radio-icon checked ' + (qTruth === v ? 'truth-icon' : '')}/>}
                                        disableRipple
                                    />
                                }
                                label={v}
                                labelPlacement="end"
                                disabled={disabled}
                            />
                        ))
                    }
                </RadioGroup>
                {this.renderAdditionalQuestion(questionObj, disabled)}
            </div>
        )
    }

    render() {
        const disabled = this.props.complete;
        return (
            <div className={'ps-10 covid-question-container chest-data'}>
                <div>
                    <p className={'covid-question-title'}>
                        {
                            !this.props.complete ? "Do you see" : "Your judgement"
                        }
                    </p>
                    <div className={'covid-questions'}>
                        {
                            question.map(v => this.renderQuestion(v, disabled))
                        }
                    </div>
                </div>
            </div>
        )
    }
}

