import React from 'react';
import PropTypes from 'prop-types';

/** Tools */
import {ReactComponent as toolZoom} from 'Assets/icons/tool-zoom.svg';
import {ReactComponent as toolCapture} from 'Assets/icons/tool-capture.svg';
import {ReactComponent as toolLayout} from 'Assets/icons/tool-layout.svg';
import {ReactComponent as toolMore} from 'Assets/icons/tool-more-menu.svg';
import {ReactComponent as toolMove} from 'Assets/icons/tool-move.svg';
import {ReactComponent as toolWindow} from 'Assets/icons/tool-window-level.svg';
import {ReactComponent as toolAnnotate} from 'Assets/icons/tool-annotate.svg';
import {ReactComponent as toolBidirectional} from 'Assets/icons/tool-bidirectional.svg';
import {ReactComponent as toolElipse} from 'Assets/icons/tool-elipse.svg';
import {ReactComponent as toolCircle} from 'Assets/icons/tool-circle.svg';
import {ReactComponent as toolLength} from 'Assets/icons/tool-length.svg';
import {ReactComponent as toolStackScroll} from 'Assets/icons/tool-stack-scroll.svg';
import {ReactComponent as toolMagnify} from 'Assets/icons/tool-magnify.svg';
import {ReactComponent as toolFlipHorizontal} from 'Assets/icons/tool-flip-horizontal.svg';
import {ReactComponent as toolFlipVertical} from 'Assets/icons/tool-flip-vertical.svg';
import {ReactComponent as toolInvert} from 'Assets/icons/tool-invert.svg';
import {ReactComponent as toolRotateRight} from 'Assets/icons/tool-rotate-right.svg';
import {ReactComponent as toolCine} from 'Assets/icons/tool-cine.svg';
import {ReactComponent as toolCrosshair} from 'Assets/icons/tool-crosshair.svg';
import {ReactComponent as toolProbe} from 'Assets/icons/tool-probe.svg';
import {ReactComponent as toolAngle} from 'Assets/icons/tool-angle.svg';
import {ReactComponent as toolReset} from 'Assets/icons/tool-reset.svg';
import {ReactComponent as toolRectangle} from 'Assets/icons/tool-rectangle.svg';
import {ReactComponent as toolFusionColor} from 'Assets/icons/tool-fusion-color.svg';
import {ReactComponent as toolCreateThreshold} from 'Assets/icons/tool-create-threshold.svg';
import {ReactComponent as toolCalibration} from 'Assets/icons/tool-calibration.svg';
import {ReactComponent as toolErase} from 'Assets/icons/tool-erase.svg';
import {ReactComponent as toolFreehand} from 'Assets/icons/tool-freehand.svg';
import {ReactComponent as toolExit} from 'Assets/icons/tool-exit.svg';
import {ReactComponent as iconMPR} from 'Assets/icons/icon-mpr-alt.svg';
import {ReactComponent as chevronDown} from 'Assets/icons/chevron-down.svg';
import {ReactComponent as arrowLeftSmall} from 'Assets/icons/arrow-left-small.svg';
import {ReactComponent as arrowRightSmall} from 'Assets/icons/arrow-right-small.svg';
import {ReactComponent as iconClose} from 'Assets/icons/icon-close.svg';
import {ReactComponent as iconPause} from 'Assets/icons/icon-pause.svg';
import {ReactComponent as iconPlay} from 'Assets/icons/icon-play.svg';

const ICONS = {
    'tool-zoom': toolZoom,
    'tool-capture': toolCapture,
    'tool-layout': toolLayout,
    'tool-more-menu': toolMore,
    'tool-move': toolMove,
    'tool-window-level': toolWindow,
    'tool-annotate': toolAnnotate,
    'tool-bidirectional': toolBidirectional,
    'tool-elipse': toolElipse,
    'tool-circle': toolCircle,
    'tool-length': toolLength,
    'tool-stack-scroll': toolStackScroll,
    'tool-magnify': toolMagnify,
    'tool-flip-horizontal': toolFlipHorizontal,
    'tool-flip-vertical': toolFlipVertical,
    'tool-invert': toolInvert,
    'tool-rotate-right': toolRotateRight,
    'tool-cine': toolCine,
    'tool-crosshair': toolCrosshair,
    'tool-probe': toolProbe,
    'tool-angle': toolAngle,
    'tool-reset': toolReset,
    'tool-rectangle': toolRectangle,
    'tool-fusion-color': toolFusionColor,
    'tool-create-threshold': toolCreateThreshold,
    'tool-calibration': toolCalibration,
    'tool-erase': toolErase,
    'tool-freehand': toolFreehand,
    'tool-exit': toolExit,
    'icon-mpr': iconMPR,
    'chevron-down': chevronDown,
    'arrow-left-small': arrowLeftSmall,
    'arrow-right-small': arrowRightSmall,
    'icon-close': iconClose,
    'icon-pause': iconPause,
    'icon-play': iconPlay,
};

function getIcon(key, props) {
    if (!key || !ICONS[key]) {
        return React.createElement('div', null, 'Missing Icon');
    }
    return React.createElement(ICONS[key], props);
}

const LocalIcon = ({name, ...otherProps}) => {
    return <React.Fragment>{getIcon(name, {...otherProps})}</React.Fragment>;
};

LocalIcon.propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default LocalIcon;
