import React, {useState, useEffect} from 'react';
import JSONParseDefault from 'json-parse-default';
import * as Apis from 'Api';

function IReferInfo({presenterInfo}) {
    const [iReferInfo, setIReferInfo] = useState(null);

    useEffect(() => {
        const info = JSONParseDefault(presenterInfo, null, {});
        if (info.iReferId) {
            Apis.getIReferInfo(info.iReferId).then((resp) => {
                setIReferInfo(resp);
            });
        }
    }, [presenterInfo]);

    if (!iReferInfo) return null;

    return (
        <div className={'irefer-container px-4 fs-15'}>
            <div className={'fs-17 fw-bold mb-1'}>iRefer Guideline ( {iReferInfo.Code} {iReferInfo.ClinicalDiagnosticIssue} )</div>
            <div className={'irefer-header fw-semi-bold border-bottom'}>
                <span>Modality</span>
                <span>Dose</span>
                <span>Recommendation</span>
                <span>Comment</span>
            </div>
            {
                iReferInfo.Investigations.map((v) => (
                    <div className={'irefer-row'} key={v.Modalities}>
                        <span>{v.Modalities}</span>
                        <span>
                            {
                                v.MaxDose === "0" ? "None" :
                                Array.from(Array(Number(v.MaxDose)).keys()).map((c) => <img src={require('Assets/icons/radiation.svg').default} key={c} alt={''}/>)
                            }
                        </span>
                        <span>{v.Recommendation}[{v.Grade}]</span>
                        <span><div dangerouslySetInnerHTML={{__html: v.Comment}} /></span>
                    </div>
                ))
            }
        </div>
    )
}

export default IReferInfo;