import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const backgroundClasses = {
    text: {
        default: '',
        primary: '',
        secondary: '',
        white: '',
        black: '',
    },
    outlined: {
        default: 'bg-transparent',
        primary: 'bg-transparent',
        secondary: 'bg-transparent',
        white: 'bg-transparent',
        black: 'bg-black',
    },
    contained: {
        default: 'bg-primary-light',
        primary: 'bg-primary-main',
        secondary: 'bg-secondary-light',
        white: 'bg-white',
        black: 'bg-black',
    },
};

const ToolbarIconButton = ({
                               children,
                               variant,
                               color,
                               size,
                               rounded,
                               disabled,
                               type,
                               fullWidth,
                               onClick,
                               className,
                               name,
                               id,
                               bgColor,
                               ...rest
                           }) => {
    const buttonElement = useRef(null);

    const handleOnClick = e => {
        buttonElement.current.blur();
        onClick(e);
    };

    const bgColorToUse = bgColor ? bgColor : backgroundClasses[variant][color];

    return (
        <button
            className={className}
            // className={classnames(
            //   baseClasses,
            //   variantClasses[variant][color],
            //   roundedClasses[rounded],
            //   sizeClasses[size],
            //   fullWidthClasses[fullWidth],
            //   disabledClasses[disabled],
            //   bgColorToUse,
            //   className
            // )}
            style={{
                padding: size === 'toolbar' ? '10px' : null,
            }}
            ref={buttonElement}
            onClick={handleOnClick}
            type={type}
            data-cy={rest['data-cy'] ?? id}
            data-tool={rest['data-tool']}
        >
            {React.cloneElement(children, {
                // className: classnames(iconSizeClasses[size], 'fill-current'),
                className: ''
            })}
        </button>
    );
};

ToolbarIconButton.defaultProps = {
    onClick: () => {
    },
    color: 'default',
    disabled: false,
    fullWidth: false,
    rounded: 'medium',
    size: 'medium',
    type: 'button',
    variant: 'contained',
};

ToolbarIconButton.propTypes = {
    children: PropTypes.node.isRequired,
    size: PropTypes.oneOf(['small', 'medium', 'large', 'initial', 'toolbar']),
    rounded: PropTypes.oneOf(['none', 'small', 'medium', 'large', 'full']),
    variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
    color: PropTypes.oneOf([
        'default',
        'primary',
        'secondary',
        'white',
        'black',
        'inherit',
    ]),
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.node,
    onClick: PropTypes.func,
};

export default ToolbarIconButton;
