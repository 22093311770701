import React, {useEffect} from 'react';
import {connect, useSelector} from "react-redux";
import {v4 as uuidv4} from 'uuid';
import {useDrop} from 'react-dnd'
import {ContextMenuTrigger} from "react-contextmenu";
import ImageViewer from "./ImageViewer";
import TestViewContextMenu from "./TestViewToolBox/TestViewContextMenu";
import MPRViewer from './MPRViewer';
import CommentInfo from "./CommentInfo";

const ImageViewerDropContainer = ({id, rowIndex, colIndex, imageList, param, viewerType}) => {
    const isBlankBox = id === '';
    id = id === '' ? uuidv4() : id;
    const [{canDrop, isOver}, drop] = useDrop({
        accept: "DicomImage",
        drop: () => ({id, rowIndex, colIndex}),
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });
    const isDragOverActive = canDrop && isOver;
    const item = imageList.find((v) => v.id === id);
    return isBlankBox ?
        <div
            className={"image " + (isDragOverActive ? 'drag-hover' : '')}
            ref={drop}
        /> : (
            viewerType === 'mpr' ?
                <MPRViewer
                    {...param}
                    imageInfo={item}
                    index={`${rowIndex}_${colIndex}`}
                    dndRef={drop}
                    isDragOver={isDragOverActive}
                /> :
                <ImageViewer
                    {...param}
                    imageInfo={item}
                    index={`${rowIndex}_${colIndex}`}
                    dndRef={drop}
                    isDragOver={isDragOverActive}
                />
        )
};

const MPRViewerContainer = ({id, imageList, resetId}) => {
    return (
        <ImageViewerDropContainer
            viewerType={'mpr'}
            id={id}
            rowIndex={0}
            colIndex={0}
            imageList={imageList}
            key={`0_0_${resetId}_${id}`}
        />
    )
}

const ImageViewerContainer = (props) => {
    const testViewState = useSelector((state) => state.testView);
    const noSynchronizerProps = {...props};
    noSynchronizerProps.synchronizer = undefined;
    const fullImageViewerRow = Number(testViewState.fullImageViewerIndex.toString().split('_')[0]);

    useEffect(() => {
    }, []);
    if(testViewState.mprModeImageId) {
        return (
            <div className={'image-container'}>
                <MPRViewerContainer id={testViewState.mprModeImageId} imageList={testViewState.imageList} resetId={testViewState.resetId}/>
            </div>
        )
    } else {
        return (
            <div className={'image-container'}>
                {testViewState.volparaImageId &&
                    <div id="images">
                        <div className={'image-row'}>
                            <ImageViewerDropContainer
                                id={testViewState.volparaImageId}
                                rowIndex={-1}
                                colIndex={-1}
                                imageList={testViewState.imageList}
                                param={noSynchronizerProps}
                                key={`${0}_${0}_${testViewState.resetId}_${testViewState.volparaImageId}`}
                            />
                        </div>
                    </div>
                }
                {/*<div id="images"> /!*className={'cursor-' + this.state.currentTool}>*!/*/}
                <ContextMenuTrigger id={'images'} renderTag='div' attributes={{id: 'images'}} holdToDisplay={-1}>
                    {
                        testViewState.showImageList.map((row, rowIndex) => (
                            <div className={'image-row'} key={rowIndex}
                                 style={(fullImageViewerRow !== -1 && fullImageViewerRow !== rowIndex) ? {display: 'none'} : {}}
                            >
                                {
                                    row.map((id, colIndex) => (
                                        <ImageViewerDropContainer
                                            id={id}
                                            rowIndex={rowIndex}
                                            colIndex={colIndex}
                                            imageList={testViewState.imageList}
                                            param={props}
                                            key={`${rowIndex}_${colIndex}_${testViewState.resetId}_${id}`}
                                        />
                                    ))
                                }
                            </div>
                        ))
                    }
                </ContextMenuTrigger>
                {/*</div>*/}
                <TestViewContextMenu
                    toolList={testViewState.toolList}
                    complete={props.complete}
                />
                <CommentInfo
                    complete={props.complete}
                    isPostTest={props.isPostTest}
                />
            </div>
        )
    }
}

export default ImageViewerContainer;