/* eslint-disable */

import * as Apis from 'Api';
import { Cookies } from 'react-cookie';
import AuditDataLoggerConfig from "./AuditDataLoggerConfig";

const cookie = new Cookies();

const logSubActivityStepTransition = (step) => {
    if (step === 1) {
        const endActivityData = {
            log_type: 'Sub-Activity',
            current_action: 'Next',
            activity_end_action: 'Next',
        };
        Atr.endSubActivity(endActivityData);
        const startActivityData = {
            activity_start_action: 'Next',
        };
        Atr.startSubActivity(startActivityData);
    } else {
        const endActivityData = {
            log_type: 'Sub-Activity',
            current_action: 'Previous',
            activity_end_action: 'Previous',
        };
        Atr.endSubActivity(endActivityData);
        const startActivityData = {
            activity_start_action: 'Previous',
        };
        Atr.startSubActivity(startActivityData);
    }
};

const logMainAndSubActivitiesEnd = (current_action) => {
    const customData = {
        log_type: 'Main-Activity',
        current_action: current_action,
        activity_end_action: current_action,
    };
    Atr.endMainActivity(customData);
    customData["log_type"] = 'Sub-Activity';
    Atr.endSubActivity(customData);
};

const startMainAndSubActivities = (current_action) => {
    const customData = {
        activity_start_action: current_action,
    };
    Atr.startMainActivity(customData);
    Atr.startSubActivity(customData);
};

const startMainActivity = (current_action) => {
    const customData = {
        activity_start_action: current_action,
    };
    Atr.startMainActivity(customData);
};

const startTimeSpentOnPlatformActivity = (current_action) => {
    Atr.endSession();  // once home Page is loaded then Atr start recording even though user is not logged in yet, hence we need to reset session once user login to start counting

    const customData = {
        activity_start_action: current_action,
    };
    Atr.setCustomData(customData);
};

const endTimeSpentOnPlatformActivity = (current_action) => {
    const customData = {
        log_type: 'Platform-Time-Spent-Activity',
        current_action: current_action,
        activity_end_action: current_action,
    };
    const mergedCustomData=Atr.mergeCustomData(customData);
    Atr.setCustomData(mergedCustomData);
    Atr.endSession();
    Atr.unsetCustomData();
};

const logEvent = (current_action) => {
    const dataTo= Atr.getTimeOnPage();
    const data = {
        log_type: 'Event',
        page_title: dataTo.title,
        current_action: current_action,
        action_location: dataTo.URL,
        labels: dataTo,
    }
    if (AuditDataLoggerConfig.shouldPushLog) {
        Apis.pushLog(data);
    }
};

const logSelectTestSetEvent = (current_action,title,url) => {

    const userId=getUserId();
    if( typeof userId === 'undefined') {
        return;
    }
    const dataTo= Atr.getTimeOnPage();
    const data = {
        log_type: 'Event',
        page_title: title,
        current_action: current_action,
        action_location: dataTo.URL+url,
        labels: dataTo,
    }
    if (AuditDataLoggerConfig.shouldPushLog) {
        Apis.pushLog(data);
    }
};

const  getUserId = () =>{
    return cookie.get('user_id');
}

export { logSubActivityStepTransition, startMainAndSubActivities, logMainAndSubActivitiesEnd, startMainActivity,
    startTimeSpentOnPlatformActivity, endTimeSpentOnPlatformActivity, logEvent, logSelectTestSetEvent };
