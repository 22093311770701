/* eslint-disable */
/**
 * Auth Actions
 * Auth Action With Google, Facebook, Twitter and Github
 */
import {NotificationManager} from 'react-notifications';
import {Cookies} from 'react-cookie';
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    LOGOUT_USER,
    SIGNUP_USER,
    SIGNUP_USER_SUCCESS,
    SIGNUP_USER_FAILURE,
    USER_COMPLETED_COUNT,
    USER_COMPLETED_POINT,
} from '../types';
import * as Apis from 'Api';
import {endTimeSpentOnPlatformActivity, startTimeSpentOnPlatformActivity, logEvent} from "../../services/AuditDataLogger";

const cookie = new Cookies();

/**
 * Redux Action To Sigin User With Email
 */
export const login = (userId, userName, userEmail, accessToken, navigate, fromUrl, callback) => (dispatch, getState) => {
    if (Apis.isLocalhost()) { // url has port, then this is localhost
        cookie.set('user_id', userId, {path: '/', domain: ''});
        cookie.set('user_name', userName, {path: '/', domain: ''});
        cookie.set('user_email', userEmail, {path: '/', domain: ''});
        cookie.set('access_token', accessToken, {path: '/', domain: ''});
        cookie.set('completed_count', 0, {path: '/', domain: ''});
        cookie.set('completed_point', 0, {path: '/', domain: ''});
    }
    dispatch({
        type: LOGIN_USER_SUCCESS, payload: {
            user: userId,
            userName: userName,
            userEmail: userEmail,
            accessToken: accessToken,
        }
    });

    startTimeSpentOnPlatformActivity("login");
    logEvent("login");

    if(navigate && fromUrl) navigate(fromUrl);
    if(callback) callback();
}

/**
 * Redux Action To Signout User From  Email
 */
export const logoutUserFromEmail = () => (dispatch) => {

    endTimeSpentOnPlatformActivity("logout");
    logEvent("logout");

    Apis.logout().then(() => {
    }).finally(() => {
        // const domain = Apis.isLocalhost() ? '' : '.detectedx.com';
        const domain = '';
        cookie.remove('user_id', {path: '/', domain});
        cookie.remove('user_email', {path: '/', domain});
        cookie.remove('user_name', {path: '/', domain});
        cookie.remove('access_token', {path: '/', domain});
        cookie.remove('completed_count', 0, {path: '/', domain});
        cookie.remove('completed_point', 0, {path: '/', domain});
        dispatch({type: LOGOUT_USER});
        NotificationManager.success('User Logout Successfully');
    });
}

/**
 * Redux Action To Signup User
 */

export const setUserCompletedCount = (count) => (dispatch) => {
    dispatch({type: USER_COMPLETED_COUNT, payload: count});
}

export const setUserCompletedPoint = (point) => (dispatch) => {
    dispatch({type: USER_COMPLETED_POINT, payload: point});
}
