import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setShowImageBrowser} from 'Store/Actions';

function SeriesButton() {
    const dispatch = useDispatch();
    const isShowImageBrowser = useSelector((state) => state.testView.isShowImageBrowser);

    return (
        <div className={"tool option"} onClick={() => dispatch(setShowImageBrowser(!isShowImageBrowser))}>
            <div className={'series-icon ' + (isShowImageBrowser ? 'active' : '')}>
                <svg name="th-large" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 13" width="1em" height="1em"
                     fill="currentColor"><title>TH Large</title>
                    <path d="M0 0h7v6H0zm8 0h7v6H8zM0 7h7v6H0zm8 0h7v6H8z"/>
                </svg>
            </div>
            <p>Series</p>
        </div>
    )
}

export default SeriesButton