import React from 'react';
import MarkerPopupNormal from "./markerPopupNormal";
import MarkerPopupLungED from "./markerPopupLungED";
import MarkerPopupWBCT from "./markerPopupWBCT";
import MarkerPopupQuiz from "./markerPopupQuiz";
import {useSelector} from "react-redux";

function MarkerPopup(props) {
    const modalityInfo = useSelector((state) => state.testView.modalityInfo);
    const testSetHangingIdList = useSelector((state) => state.testView.testSetHangingIdList);
    if(modalityInfo.name === 'LungED') {
        return <MarkerPopupLungED {...props} />
    } else if(modalityInfo.modality_type === 'wb_ct') {
        return <MarkerPopupWBCT {...props} />
    } else if(modalityInfo.modality_type === 'quiz' || modalityInfo.name === 'Questions & Answers') {
        return <MarkerPopupQuiz {...props} />
    } else {
        let ratings = props.ratings;
        let lesionNameText = 'Lesion';
        let isBreastPopup = testSetHangingIdList.length > 0 && props.ratings.length > 0;
        if(modalityInfo.name === 'Trauma') {
            // trauma modality has 0 ~ 5 rating
            ratings = Array.from(Array(6).keys()).map((v) => ({label: v, value: v}));
            lesionNameText = 'Abnormality';
            isBreastPopup = false;
        } else if (modalityInfo.modality_type === 'breasted_mri') {
            isBreastPopup = true;
        }

        return <MarkerPopupNormal {...props} ratings={ratings} lesionNameText={lesionNameText} isBreastPopup={isBreastPopup}/>
    }
}

export default MarkerPopup;


