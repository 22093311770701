import React from 'react';
import {ContextMenu, MenuItem} from "react-contextmenu";
import {connect} from "react-redux";
import {changeCurrentTool, changeHangingLayout} from "Store/Actions";
import LocalIcon from "Components/LocalIcon";

const contextToolList = [
    {
        id: 'Pan',
        label: 'Pan',
        icon: 'tool-move',
        key: '[P]',
        showInComplete: true,
    },
    {
        id: 'Zoom',
        label: 'Zoom',
        icon: 'tool-zoom',
        key: '[Z]',
        showInComplete: true,
    },
    {
        id: 'Magnify',
        label: 'Magnify',
        icon: 'tool-magnify',
        key: '[G]',
        showInComplete: true,
    },
    {
        id: 'Wwwc',
        label: 'Window',
        icon: 'tool-window-level',
        key: '[W]',
        showInComplete: true,
    },
    {
        id: 'Length',
        label: 'Length',
        icon: 'tool-length',
        key: '[L]',
        showInComplete: false,
    },
    {
        id: 'Angle',
        label: 'Angle',
        icon: 'tool-angle',
        key: '[A]',
        showInComplete: false,
    },
    {
        id: 'EllipticalRoi',
        label: 'Ellipse',
        icon: 'tool-elipse',
        key: '[E]',
        showInComplete: false,
    },
    {
        id: 'RectangleRoi',
        label: 'Rectangle',
        icon: 'tool-rectangle',
        key: '[O]',
        showInComplete: false,
    },
    {
        id: 'ArrowAnnotate',
        label: 'Arrow',
        icon: 'tool-annotate',
        key: '[T]',
        showInComplete: false,
    },
    {
        id: 'Eraser',
        label: 'Eraser',
        icon: 'tool-erase',
        key: '[X]',
        showInComplete: false,
    },
    {
        id: 'Marker',
        label: 'Marker',
        icon: 'tool-probe',
        key: '[M]',
        showInComplete: false,
    },
    {
        id: 'MarkerFreehand',
        label: 'Freehand',
        icon: 'tool-freehand',
        key: '[F]',
        showInComplete: false,
    }
]

const TestViewContextMenu = ({toolList, complete, currentTool, changeHangingLayout, changeCurrentTool}) => {
    const onClickMenuItem = (e, data) => {
        if (data.tool === 'Reset') {
            changeHangingLayout('reset');
        } else {
            changeCurrentTool(data.tool);
        }
    }
    return (
        <ContextMenu id="images" className='test-view-context-menu'>
            {
                contextToolList.filter((v) => toolList.includes(v.id)).filter((v) => !complete ? true : v.showInComplete).map((v) => (
                    <MenuItem key={v.id} data={{tool: v.id}} onClick={onClickMenuItem} attributes={{className: currentTool === v.id ? 'tool-selected' : ''}}>
                        <div className={'d-flex flex-row align-items-center'}>
                            <LocalIcon name={v.icon}/>
                            <span>{v.label}</span>
                        </div>
                        <span className='shortcut-key'>{v.key}</span>
                    </MenuItem>
                ))
            }
            <MenuItem data={{tool: 'Reset'}} onClick={onClickMenuItem}>
                <div className={'d-flex flex-row align-items-center'}>
                    <LocalIcon name={'tool-reset'}/>
                    <span>Reset</span>
                </div>
                <span className='shortcut-key'>[R]</span>
            </MenuItem>
        </ContextMenu>
    )
}


// map state to props
const mapStateToProps = (state) => {
    return {
        currentTool: state.testView.currentTool
    };
};

export default connect(mapStateToProps, {
    changeHangingLayout,
    changeCurrentTool
})(TestViewContextMenu);
